import { useAuth } from '../context/authContext';
import { useNavigate } from 'react-router-dom';

const AuthGoogle = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  if (!auth) {
    return null;
  } else if (auth.user) {
    // Si el usuario ya está autenticado, redirige a la ruta /forms
    navigate('/forms');
    return null; // No es necesario renderizar nada más
  }

  const handleGoogle = (e) => {
    e.preventDefault();
    auth.loginWithGoogle();
  };
  

  return (
    <div>
      <button className="btn btn-primary" onClick={(e) => handleGoogle(e)}>
        Google
      </button>
    </div>
  );
};

export default AuthGoogle;
