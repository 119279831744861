import {auth} from '../firebase/firebase.config';
import React, {createContext,useContext, useState, useEffect} from 'react';
import { GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged} from 'firebase/auth';

export const authContext = createContext();

export const useAuth = () => {
    const context = useContext(authContext);
    if(!context) throw new Error('useAuth debe estar dentro del proveedor AuthContext');
    return context;
}

export function AuthProvider({children}) {
 
    const [user, setUser] = useState("");
    useEffect(() => {
        const suscribe = onAuthStateChanged(auth, (user) => {
            if(user) {
                setUser(user);
            } else {
                console.log('no hay usuario');
                setUser(null);
            }
        })
        return suscribe;
    }
    ,[])

    const loginWithGoogle  = async () => {
       const responseGoogle = new GoogleAuthProvider();
       return await signInWithPopup(auth,responseGoogle);
    }
    const logout = async () => {
        const response = await signOut(auth);
        console.log(response);
    }
    return <authContext.Provider
    value={{loginWithGoogle, 
            logout, user}}>{children}</authContext.Provider>
}