// FormsPage.jsx
import React from "react";
import { Route, Routes } from "react-router-dom";
import FormModule from "../components/FormModule";
import ModuleQuestionsFinal from "../components/ModuleQuestionsFinal";
import "../styles/FormsPage.css";

const FormsPage = () => {
  return (
    <div className="forms-page">
      <Routes>
        <Route
          path="/forms/1"
          element={
            <ModuleQuestionsFinal moduleTitle="Módulo 1: Generalidades del Funcionamiento del Laboratorio" id="1" />
          }
        />
        <Route
          path="/forms/2"
          element={
            <ModuleQuestionsFinal moduleTitle="Módulo 2: Estructura Organizacional" id="2"/>
          }
        />
        <Route
          path="/forms/3"
          element={
            <ModuleQuestionsFinal moduleTitle="Módulo 3: Recursos humanos" />
          }
        />
        <Route
          path="/forms/4"
          element={
            <ModuleQuestionsFinal moduleTitle="Módulo 4: Recursos ambientales" />
          }
        />
        <Route
          path="/forms/5"
          element={
            <ModuleQuestionsFinal moduleTitle="Módulo 5: Recursos de equipamiento" />
          }
        />
        <Route
          path="/forms/6"
          element={
            <ModuleQuestionsFinal moduleTitle="Módulo 6: Trazabilidad" />
          }
        />
        <Route
          path="/forms/7"
          element={
            <ModuleQuestionsFinal moduleTitle="Módulo 7: Productos y servicios externos" />
          }
        />
        <Route 
          path="/forms/8"
          element={
            <ModuleQuestionsFinal moduleTitle="Módulo 8: Requisitos de procesos" />
          }
        />

      </Routes>
      <FormModule
        title="Módulo 1: Funcionamiento del Laboratorio"
        description=" Principios fundamentales para garantizar la imparcialidad y confidencialidad en el funcionamiento del laboratorio."
        path="/forms/1"
        className="form-module"
      />
      <FormModule
        title="Módulo 2: Estructura Organizacional"
        description="Organización interna y roles clave para salvaguardar la imparcialidad."
        path="/forms/2"
        className="form-module"
      />
      <FormModule
        title="Módulo 3: Recursos humanos"
        description="Gestión del personal y contratos para garantizar la confidencialidad."
        path="/forms/3"
        className="form-module"
      />
      <FormModule
        title="Módulo 4: Recursos ambientales"
        description="Prácticas sostenibles y manejo adecuado de recursos para minimizar impactos ambientales."
        path="/forms/4"
        className="form-module"
      />
      <FormModule
        title="Módulo 5: Recursos de equipamiento"
        description="Gestión y mantenimiento de equipos para cumplir con estándares y normativas."
        path="/forms/5"
        className="form-module"
      />
      <FormModule
        title="Módulo 6: Trazabilidad"
        description="Establecimiento y mantenimiento de la trazabilidad en todos los procesos del laboratorio."
        path="/forms/6"
        className="form-module"
      />
      <FormModule
        title="Módulo 7: Productos y servicios externos"
        description="Evaluación y gestión de productos y servicios externos para garantizar la calidad."
        path="/forms/7"
        className="form-module"
      />
      <FormModule
        title="Módulo 8: Requisitos de procesos"
        description="Definición y documentación de requisitos específicos para asegurar procesos de alta calidad."
        path="/forms/8"
        className="form-module"
      />
      
    </div>
  );
};

export default FormsPage;
