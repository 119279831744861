import React from 'react';
import '../styles/About.css';
const About = () => {
    return (
          <div id="about-us" className="about-us-section">
          <h2 className="about-us-title">Sobre Nosotros</h2>
          <p className="about-us-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget odio eget est blandit ullamcorper eu ut eros. Curabitur ac lectus id elit cursus rhoncus.
          </p>
        </div>
    );
};

export default About;
