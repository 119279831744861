import React from 'react';
import { Routes, Route, Navigate, BrowserRouter as Router } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Navbar from './components/Navbar';
import FormsPage from './pages/FormsPage';
import Divider from './components/Divider';
import Footer from './components/Footer';
import ModuleQuestionsFinal from './components/ModuleQuestionsFinal';
import PrivateRoute from './middleware/PrivateRoute';
import { AuthProvider } from './context/authContext';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/forms/*" element={<PrivateRoute ><FormsPage /></PrivateRoute> } />
          <Route path="*" element={<h1>Not Found</h1>} />
          <Route path="/forms/:id" element={<PrivateRoute> <ModuleQuestionsFinal /> </PrivateRoute>} />
        </Routes>
        <Divider />
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default App;
