import React from "react";
import "../styles/LandingPage.css";
import backgroundImage from "../assets/landing_photo.png"; // Asegúrate de ajustar la ruta según la ubicación de tu imagen
import About from "../components/About";
import Divider from "../components/Divider";
import AuthGoogle from "../components/AuthGoogle";
import { useAuth } from '../context/authContext'
const LandingPage = () => {
  let name = "Invitado";
  const auth = useAuth()
  if (!auth) {
      return null
  }
  if (auth.user) {
    name = auth.user.displayName
  }

  return (
      <div className="landing-page">
        <div className="landing-content">
          <div className="landing-image-div">
            <img
              className="landing-image"
              src={backgroundImage}
              alt="Landing"
            />
            <div className="cta-box">
              <div className="cta-content">
                <h2
                  style={{
                    fontSize: "3em",
                    fontFamily: "Louis George Café, sans-serif",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  ¡Simplificate!
                </h2>
                <p
                  style={{
                    fontSize: "1.5em",
                    fontFamily: "Louis George Café, sans-serif",
                    fontWeight: 100,
                  }}
                >
                  Haz que la validación ISO 17025 sea un proceso simple y sin
                  complicaciones para tu laboratorio con ValidArte.
                </p>
                <p
                  style={{
                    fontSize: "1.26em",
                    fontFamily: "Louis George Café, sans-serif",
                    fontWeight: 100,
                  }}
                >
                  Con ValidArte, la verificación y la documentación de tus
                  procedimientos de laboratorio se convierten en tareas rápidas
                  y fluidas. 
                </p>
                <AuthGoogle />

              </div>
            </div>
          </div>
      
        </div>
        <Divider />
      

      </div>
  );
};

export default LandingPage;
