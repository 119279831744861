import React, { useState } from "react";

import "../styles/Navbar.css";
import { Link, NavLink } from "react-router-dom";
import WhiteLogo from "../assets/logo-blanco.png";
import { useAuth } from "../context/authContext";
import { MdArrowForwardIos } from "react-icons/md";


const Navbar = () =>{
  const [menuOpen, setMenuOpen] = useState(false);
  const auth = useAuth();

  const logout = async () => {
    await auth.logout();
    // Redirige a la ruta /
    window.location.assign("/");

  }
  if (!auth) {
    return null;
  } else if (auth.user) {
    return (
      <nav>
        <Link to="/" className="title">
          {/* Inserta logo */}
          <img src={WhiteLogo} alt="logo" className="logo"/>
          <span className="logoText2">VALID</span>
          <span className="logoText">ARTE</span>
        </Link>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={menuOpen ? "open" : ""}>
          <li>
            <NavLink to="/about">Sobre nosotros</NavLink>
          </li>
          <li>
            <NavLink to="/services">Servicios</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contacto</NavLink>
          </li>
          <li className="logout">
            
            <button onClick={logout}>Cerrar sesión</button>

            
          </li>
        </ul>
      </nav>
    );
  }

  return (
      <nav className={'color-nav'}>
        <Link to="/" className="title">
          <img src={WhiteLogo} alt="logo" className="logo"/>
        </Link>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={menuOpen ? "open" : ""}>
          <li>
            <NavLink to="/about">Inicio</NavLink>
          </li>
          <li>
            <NavLink to="/services">Nosotros</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Servicio</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contacto</NavLink>
          </li>
        </ul>
        <NavLink className="login" to={'/login'}>
            Ingresar
            <MdArrowForwardIos color={'white'}/>
        </NavLink>
      </nav>
  );
};

export default Navbar;