import React, { Children } from 'react';
import { Navigate, Route } from 'react-router-dom';
import {  useAuth } from './../context/authContext';

const PrivateRoute = ({ children }) => {
    let name = "Invitado";
    const auth = useAuth()
    if (!auth) {
        return null
    }
    if (auth.user) {
      name = auth.user.displayName
    }
    return auth.user ? (
        children
        ) : (
        <Navigate to="/" />
        );

};

export default PrivateRoute;
