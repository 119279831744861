import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../styles/ModuleQuestions.css";
import jsPDF from "jspdf";
import LogoImage from "../assets/logo.png";
import 'jspdf-autotable';
import '../assets/fonts/Exo-normal.js';
import '../assets/fonts/LouisGeorgeCafe-normal.js';

const ModuleQuestionsFinal = () => {

    const {id} = useParams();
    console.log(id);
    
  // Estado para almacenar las preguntas respondidas con "No"
  const [preguntasNo, setPreguntasNo] = useState([]);
  const [preguntasSi, setPreguntasSi] = useState([]);
  // { "valor": "Todos", "etiqueta": "Todos" },
  // { "valor": "En su mayoría", "etiqueta": "En su mayoría" },
  // { "valor": "Algunos pocos", "etiqueta": "Algunos pocos" },
  // { "valor": "Ninguno", "etiqueta": "Ninguno" }
  const [preguntasTodos, setPreguntasTodos] = useState([]);
  const [preguntasEnSuMayoria, setPreguntasEnSuMayoria] = useState([]);
  const [preguntasAlgunosPocos, setPreguntasAlgunosPocos] = useState([]);
  const [preguntasNinguno, setPreguntasNinguno] = useState([]);


  const [showAlert, setShowAlert] = useState(false);
  const [preguntas, setPreguntas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        // Importa dinámicamente el módulo JSON según el id
        const datosModule = await import(`../assets/data/preguntas${id}.json`);

        // Accede al default export del módulo (el contenido del JSON)
        const datosJSON = datosModule.default;
        console.log(datosJSON);
        // Establece los datos en el estado
        setPreguntas(datosJSON);
      } catch (error) {
        console.error('Error al cargar datos:', error);
      }
    };

    // Llama a la función para cargar los datos cuando el componente se monta
    cargarDatos();
    setLoading(false);
  }, [id]);



  // Función para manejar la respuesta a una pregunta
  const handleRespuesta = (id, respuesta) => {
    // Si la respuesta esta en dos array de preguntas la elimina y la agrega al otro
    // si la respuesta no esta en ningun array, la agrega al correspondiente
    // tomando en cuenta los casos de respuesta "Todos", "En su mayoría", "Algunos pocos", "Ninguno", "Si", "No"
    if (preguntasTodos.includes(id)) {
      setPreguntasTodos(preguntasTodos.filter((item) => item !== id));
      setPreguntasEnSuMayoria(preguntasEnSuMayoria.filter((item) => item !== id));
      setPreguntasAlgunosPocos(preguntasAlgunosPocos.filter((item) => item !== id));
      setPreguntasNinguno(preguntasNinguno.filter((item) => item !== id));
      //Settea en el array correspondiente a la respuesta
      if (respuesta === "Todos") {
        setPreguntasTodos([...preguntasTodos, id]);
      }
      else if (respuesta === "En su mayoría") {
        setPreguntasEnSuMayoria([...preguntasEnSuMayoria, id]);
      }
      else if (respuesta === "Algunos pocos") {
        setPreguntasAlgunosPocos([...preguntasAlgunosPocos, id]);
      }
      else if (respuesta === "Ninguno") {
        setPreguntasNinguno([...preguntasNinguno, id]);
      }

    } else if (preguntasEnSuMayoria.includes(id)) {
      setPreguntasTodos(preguntasTodos.filter((item) => item !== id));
      setPreguntasEnSuMayoria(preguntasEnSuMayoria.filter((item) => item !== id));
      setPreguntasAlgunosPocos(preguntasAlgunosPocos.filter((item) => item !== id));
      setPreguntasNinguno(preguntasNinguno.filter((item) => item !== id));
      //Settea en el array correspondiente a la respuesta
      if (respuesta === "Todos") {
        setPreguntasTodos([...preguntasTodos, id]);
      }
      else if (respuesta === "En su mayoría") {
        setPreguntasEnSuMayoria([...preguntasEnSuMayoria, id]);
      }
      else if (respuesta === "Algunos pocos") {
        setPreguntasAlgunosPocos([...preguntasAlgunosPocos, id]);
      }
      else if (respuesta === "Ninguno") {
        setPreguntasNinguno([...preguntasNinguno, id]);
      }
      
    } else if (preguntasAlgunosPocos.includes(id)) {
      setPreguntasTodos(preguntasTodos.filter((item) => item !== id));
      setPreguntasEnSuMayoria(preguntasEnSuMayoria.filter((item) => item !== id));
      setPreguntasAlgunosPocos(preguntasAlgunosPocos.filter((item) => item !== id));
      setPreguntasNinguno(preguntasNinguno.filter((item) => item !== id));
      //Settea en el array correspondiente a la respuesta
      if (respuesta === "Todos") {
        setPreguntasTodos([...preguntasTodos, id]);
      }
      else if (respuesta === "En su mayoría") {
        setPreguntasEnSuMayoria([...preguntasEnSuMayoria, id]);
      }
      else if (respuesta === "Algunos pocos") {
        setPreguntasAlgunosPocos([...preguntasAlgunosPocos, id]);
      }
      else if (respuesta === "Ninguno") {
        setPreguntasNinguno([...preguntasNinguno, id]);
      }
      
    } else if (preguntasNinguno.includes(id)) {
      setPreguntasTodos(preguntasTodos.filter((item) => item !== id));
      setPreguntasEnSuMayoria(preguntasEnSuMayoria.filter((item) => item !== id));
      setPreguntasAlgunosPocos(preguntasAlgunosPocos.filter((item) => item !== id));
      setPreguntasNinguno(preguntasNinguno.filter((item) => item !== id));
      //Settea en el array correspondiente a la respuesta
      if (respuesta === "Todos") {
        setPreguntasTodos([...preguntasTodos, id]);
      }
      else if (respuesta === "En su mayoría") {
        setPreguntasEnSuMayoria([...preguntasEnSuMayoria, id]);
      }
      else if (respuesta === "Algunos pocos") {
        setPreguntasAlgunosPocos([...preguntasAlgunosPocos, id]);
      }
      else if (respuesta === "Ninguno") {
        setPreguntasNinguno([...preguntasNinguno, id]);
      }
      
    } else if (preguntasNo.includes(id)) {
      setPreguntasNo(preguntasNo.filter((item) => item !== id));
      setPreguntasSi([...preguntasSi, id]);
    } else if (preguntasSi.includes(id)) {
      setPreguntasSi(preguntasSi.filter((item) => item !== id));
      setPreguntasNo([...preguntasNo, id]);
    }
      else if (respuesta === "No") {
      setPreguntasNo([...preguntasNo, id]);
    } else if (respuesta === "Si") {
      setPreguntasSi([...preguntasSi, id]);
    } else if (respuesta === "Todos") {
      setPreguntasTodos([...preguntasTodos, id]);
    } else if (respuesta === "En su mayoría") {
      setPreguntasEnSuMayoria([...preguntasEnSuMayoria, id]);
    } else if (respuesta === "Algunos pocos") {
      setPreguntasAlgunosPocos([...preguntasAlgunosPocos, id]);
    } else if (respuesta === "Ninguno") {
      setPreguntasNinguno([...preguntasNinguno, id]);
    }
  };

const InfoIcon = ({ tooltipText }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className={`info-icon ${showTooltip ? 'fade-in' : 'fade-out'}`}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {/* Si está sobre el mouse se muestra el contenido */}
      {showTooltip ? (
        <div className="tooltip-content">{tooltipText}</div>
      ) : (
      //  Se muestra en todo momento
        <span className="tooltip-icon">
          Info
        </span>

      )}
    </div>
  );
};

  const handleAlert = () => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  // Funcion evaluar permite obtener el valor de las preguntas respondidas con "No"
  // Y genera un pdf con la informacion de las preguntas respondidas con "No"
  // y las acciones a realizar
  const evaluar = () => {
    //Si no estan todas la preguntas respondidas
    // a traves de suma de los array de preguntas respondidas con "No", "Si", "Todos", "En su mayoría", "Algunos pocos", "Ninguno"
    // se verifica si la suma es igual a la cantidad de preguntas
    // si no es igual se muestra un alerta
    const preguntasRespondidas = preguntasNo.length + preguntasSi.length + preguntasTodos.length + preguntasEnSuMayoria.length + preguntasAlgunosPocos.length + preguntasNinguno.length;
    console.log(preguntasTodos);
    console.log(preguntasEnSuMayoria);
    console.log(preguntasAlgunosPocos);
    console.log(preguntasNinguno);
    console.log(preguntasSi);
    console.log(preguntasNo);
    if (preguntasRespondidas < preguntas.length) {
      console.log(preguntasRespondidas, preguntasNo.length, preguntasSi.length);
      handleAlert();
      return;
    }
    //Hace un array sumando preguntasNo y preguntasNinguno\
    const preguntasAccion = preguntasNo.concat(preguntasNinguno);
    console.log(preguntasAccion,"preguntasAccion");
    // Generar el pdf
    const pdfDoc = new jsPDF();
    // Obtener el texto de la acción de cada pregunta respondida con "No"
    const acciones = preguntasAccion.map((id) => {
      const actionText = preguntas.find((pregunta) => pregunta.id === id).accionNo;
        return actionText;
    });

    // Obtener el texto de la información de cada pregunta respondida con "No"
    const informaciones = preguntasAccion.map((id) => {
      const infoText = preguntas.find((pregunta) => pregunta.id === id).pregunta;
      return infoText;
    });



    //Obtener la respuesta de cada pregunta respondida con "No"
    const respuestas = preguntasAccion.map((id) => {
      const respuesta = document.querySelector(
        `input[name=${id}]:checked`
      ).value;
      return respuesta;
    });

    // Genera merge de las acciones, informaciones y respuestas
    const rows = acciones.map((accion, index) => {
      return [
        informaciones[index],
        respuestas[index],
        accion,
      ];
    });

    //Inserta el logo en el pdf
    const img = new Image();
    img.src = LogoImage;
    pdfDoc.addImage(img, "PNG", 10, 9, 10, 8);
    // Inserta el titulo del pdf
    pdfDoc.setFontSize(20);
    pdfDoc.setFont("Exo", "normal");
    pdfDoc.text("VALID", 20, 17);
    // Rellena VALID con ARTE pero en verde
    pdfDoc.setTextColor(29, 191, 125);
    pdfDoc.text("ARTE", 40, 17);
    pdfDoc.setTextColor(40, 43, 40);

    pdfDoc.setFont("Louis George Cafe", "normal");
    pdfDoc.setFontSize(18);
    pdfDoc.text(
      "Validación de la implementación de la norma ISO/IEC 17025:2017",
      14,
      30
    );
    pdfDoc.setFontSize(14);
    // Encabezado del PDF
    pdfDoc.text("Informe de Avance del Formulario modulo 1", 14, 40);

    // Contenido del PDF
    const columns = ["Pregunta", "Respuesta", "Acción"];
    var yPosition = 50;

    if (preguntasAccion.length === 0) {
      pdfDoc.text("No hay preguntas sin responder", 14, 50);
      yPosition = 65;
    }
    pdfDoc.autoTable({
      startY: yPosition,
      head: [columns],
      body: rows,
      styles: {
        fontSize: 10,
        cellPadding: 1,
        overflow: "linebreak",
        columnWidth: "wrap",
        valign: "middle",
        font: "Louis George Cafe",
      },
      columnStyles: {
        0: { columnWidth: 80 },
        1: { columnWidth: 20 },
        2: { columnWidth: 80 },
      },
    });

    // Calcula el final de la tabla
    const finalY = pdfDoc.lastAutoTable.finalY;

    // Porcentaje de avance
    var porcentajeAvance = ((12 - preguntasAccion.length) * 100) / 12;
    //Redondea el porcentaje de avance
    porcentajeAvance = Math.round(porcentajeAvance * 100) / 100;

    pdfDoc.text(`Porcentaje de avance: ${porcentajeAvance}%`, 14, finalY + 10);

    // Guardar o mostrar el PDF
    pdfDoc.save("Informe_Avance_Formulario.pdf");

    alert("Informe generado con éxito");
    // Redirecciona a los formularios de inicio
    window.location.href = "/forms";

  };
  if (loading) {
    return <p>Cargando preguntas...</p>;
  }

  return (
    <div>
      {preguntas.map((pregunta) => (
        <div className="question" key={pregunta.id}>
          <div className="question-text">
            <p>
              {pregunta.pregunta}

              {pregunta.tooltip && (
                <InfoIcon tooltipText={pregunta.tooltip} />
              )}

              
            </p>
          </div>
          <span className="question-info" id={`i${pregunta.id}`}></span>
          <div className="question-options">
            {pregunta.opciones.map((opcion) => (
              <label key={opcion.valor}>
                <input
                  type="radio"
                  name={pregunta.id}
                  value={opcion.valor}
                  onChange={() => handleRespuesta(pregunta.id, opcion.valor)}
                />{" "}
                {opcion.etiqueta}
              </label>
            ))}
          </div>
          <p className="action">{pregunta.accionNo}</p>
        </div>
      ))}
       {/* Genera boton evaluar */}
       <button
        className="btn-evaluar"
        onClick={() =>evaluar()}>
        Evaluar
      </button>
      <div className={`alert_query ${showAlert ? 'fade-in' : 'fade-out'} ${!showAlert && 'hidden'}`}>
        {/* Contenido de tu alerta */}
        No se han respondido todas las preguntas
      </div>
    </div>
  );
};

export default ModuleQuestionsFinal;
