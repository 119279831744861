// FormModule.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/FormModule.css';

const FormModule = ({ title, description, path }) => {
  return (
    <Link to={path} className="form-module-link">
      <div className="form-module">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </Link>
  );
};

export default FormModule;
